import React from "react";
import { connect } from "react-redux";
import * as Ant from "antd";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";

const Wrapper = styled.div`
  margin: 20px;

  & h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
    font-size: 21px;
  }

  & > .form-wrapper {
    border: 0.5px solid #ccc;
    background-color: #fff;
    padding: 20px;
  }

  & .title {
    padding: 8px 10px;
    font-size: 16px;
    background-color: #c89664;
    color: #fff;
    margin-bottom: 20px;
  }

  & .context {
    padding: 0px 20px 20px 0px;
    margin-bottom: 20px;
    border-bottom: 0.5px solid #cccccc;
  }

  & .subtitle {
    font-size: 16px;
    color: #717071;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  & p {
    margin-left: 10px;
    margin-bottom: 0px;
    color: #717071;
    font-size: 14px;
  }
`;

class MonthlyPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      haveRead: false,
    };
  }

  render() {
    let { haveRead } = this.state;
    let { onEntry } = this.props;
    console.log("haveRead", haveRead);
    return (
      <Wrapper>
        <h2>月結服務</h2>
        <div className="form-wrapper">
          <div className="title">月結服務說明</div>
          <div className="context">
            <div className="subtitle">月結會員專屬服務</div>
            <p>• 運費每月結算一次，提供您方便又省時的服務。</p>
            <p>• 提供您每月帳單報表資料。</p>
            <p>• 享有30天還款期，提高企業資金效益。</p>
            <p>• 優先獲取平台相關業務信息或資料。</p>
            <p>• 優先體驗和試用新服務。</p>
            <p>• 優先體驗和試用新服務。</p>
            <p>• 擁有比普通客戶更多、更方便的增值服務選擇。</p>
            <div className="subtitle">申請加入條件</div>
            <p>• 申請開通月結帳戶前，必須先擁有本服務企業會員帳號。</p>
            <p>
              •
              月結會員僅限於公司、商行、工廠及協會等具有統一編號之營利事業或財團法人。
            </p>
            <p>• 月結會員申請時填寫之相關資料使用適用於本服務之隱私權政策。</p>
            <div className="subtitle">申請準備資料</div>
            <p>
              若您符合以上申請條件，請填寫『企業月結會員申請』資料，並備妥以下資料檔案掃描檔或影本：
            </p>
            <p>1. 勾選同意下方月結服務說明，並填寫『月結會員申請』資料</p>
            <p>
              2.
              公司具有營業登記、商業登記、稅籍登記、財團法人及社團法人的登記(以上檔案擇一)，需持有具法律效力登記證明的營業執照。
            </p>
            <p>3. 提供公司行號支存帳號資掃描檔或影本。</p>
            <p>以上資料送審後，我們將會盡快審核您提出的申請。</p>
            <div className="subtitle">如何變更月結帳戶資料?</div>
            <p>
              如您有財務資料或公司基本資料異動需求，請寄送申請變更月結會員資料寄送電子郵件至：shop@cybertek.com.tw，
            </p>
            <p>
              或請於平台服務時間來電客服專線：07-310-8866
              #113，由專人為您服務修改。
            </p>
          </div>
          <div
            onClick={() => this.setState({ haveRead: !haveRead })}
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              type="checkbox"
              checked={haveRead}
              onChange={e => this.setState({ haveRead: e.target.checked })}
            />
            <p>{`申請前請務必至詳細閱讀『 客服中心>企業會員交易須知 』，以免影響您的權益謝謝。
我已閱讀以上月結服務說明，同意遵守『月結客戶付款注意事項』，並申請成為服務平台之企業月結會員。`}</p>
          </div>
          <Ant.Button
            type="primary"
            onClick={haveRead ? onEntry : () => alert("請先閱讀完說明")}
            style={{
              margin: 10,
              flexGrow: 0,
              backgroundColor: "#c89664",
              border: 0,
            }}
          >
            下一步填寫申請成為月結會員
          </Ant.Button>
        </div>
      </Wrapper>
    );
  }
}

export default connect(null, ActionCreator)(MonthlyPanel);
