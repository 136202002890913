import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import StatusLabelGenerator from "./statusLabel";
import * as Icon from "./Icon";
import OrderStatusBar from "./OrderStatusBar";
import { getOrderStatus } from "../Utils/OrderUtil";

const TableTypes = {
  NORMAL: 0,
  BUY_CREDITS_ORDER: 1,
  PAY_BY_CREDIT: 2,
  MONTHLY: 3,
};

const OrderTypeOptions = [
  { value: "waiting", label: "未付款", color: "#C24C4C" },
  { value: "processing", label: "未付款", color: "#4BAA70" },
  { value: "success", label: "已付款", color: "#4BAA70" },
  { value: "failure", label: "付款失敗", color: "#D7BD23" },
];

const OrderDeliveryStatus = [
  { value: "tallying", label: "理貨中", color: "#D7BD23" },
  {
    value: "form_generation",
    label: "待填出貨表單",
    color: "#D7BD23",
  },
  { value: "form_completed", label: "出貨中", color: "#4BAA70" },
  { value: "delivered", label: "已送達", color: "#4BAA70" },
];
const DELIVERY_TYPE = {
  self_pick: "自取",
  special_car: "專車配送",
  mailing: "宅配-郵寄",
  hct: "宅配-新竹貨運",
};
const PAYMENT_STATUS = {
  waiting: "未付款",
  processing: "未付款",
  code_generated: "未付款",
  success: "已付款",
  failure: "付款失敗",
};

const PAYMENT_TYPE = {
  credits: "點數付款",
  neweb: "藍新付款",
  ecpay_cvs: "超商代碼繳費",
  ecpay: "信用卡付款",
  ecpay_atm: "ATM繳費",
  offline: "待臨櫃匯款確認",
  //點數付款
};

const _generateOrderStatusDetail = (status, type) => {
  // TODO: now is using payment_subtype, these types are faded out !!!!
  if (status === "waiting" && type === "offline") {
    return "等待臨櫃繳款中";
  } else if (status === "waiting" && type === "ecpay_cvs") {
    return "等待超商繳款中";
  } else if (status === "waiting" && type === "ecpay_atm") {
    return "等待ATM繳款中";
  } else {
    return "-";
  }
};
//string -> json
const parseCartData = order => {
  try {
    return JSON.parse(order.cart.replace(/\\\\/g, "\\"));
  } catch (ex) {
    console.warn("parse cart data error!!");
    return null;
  }
};

const OrderTable = ({
  orders,
  loading,
  onItemClick,
  mounted = false,
  type = TableTypes.NORMAL,
}) => {
  const columns = [
    {
      title: "訂單編號",
      render: order => <a onClick={() => onItemClick(order)}>{order.id}</a>,
      width: 100,
      fixed: "left",
    },
    {
      title: "付款狀態",
      render: (_, order) => PAYMENT_STATUS[order.payment_status],
      width: 70,
    },
    {
      title: "付款方式",
      render: (_, order) => PAYMENT_TYPE[order.payment_type],
      width: 70,
    },
    {
      title: "總價",
      dataIndex: "amount",
      width: 70,
    },
    {
      title: "建立時間",
      render: (_, order) =>
        ` ${order.created.slice(0, 10)} ${order.created.slice(11, 16)}`,
      width: 100,
    },
    {
      //for expend icon ui index workaround
      title: "",
      render: (_, order) => ``,
      width: 1,
    },
  ];

  const extraProps = mounted
    ? {
        scroll: {
          x: window.innerWidth - 200 - 500,
        },
      }
    : {};

  return (
    <Ant.Table
      loading={loading}
      columns={columns}
      dataSource={orders.map((order, idx) => {
        order.key = idx;
        return order;
      })}
      pagination={{ pageSize: 20 }}
      {...extraProps}
    />
  );
};

export default OrderTable;

export { TableTypes };
