import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import ActionCreator from "../ActionCreator";
import ProductItem from "./ProductItem";
import * as Catalog from "../Contexts/CatalogContext";
import * as Cart from "../Contexts/CartContext";
import * as Theme from "../Theme";

class MyCourseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sort: "+created",
      category: "",
      courses: [],
    };
  }

  async componentDidMount() {
    this._fetchMyCourseList();
  }

  render() {
    let { courses, loading } = this.state;
    let { navActions } = this.props;
    return (
      <Wrapper>
        <h2>我的課程</h2>

        <Ant.Col
          xs={24}
          sm={24}
          md={24}
          lg={22}
          style={{ padding: "30px 20px" }}
        >
          {loading && (
            <Ant.Spin style={{ display: "block", margin: "0 auto" }} />
          )}
          {!loading &&
            (courses.length == 0 ? (
              <p>您尚未購買課程</p>
            ) : (
              <Grid>
                {courses.map((course, idx) => {
                  return (
                    <ProductItem
                      deadline={course.deadline}
                      key={idx}
                      product={course.product}
                      onClick={() => {
                        navActions.push(`/product?id=${course.product.id}`);
                      }}
                    />
                  );
                })}

                {/* compensate blank item for ui: justfy-content: space-between */}
                {[0, 1, 2].map(e => (
                  <ProductItem key={e} product={null} />
                ))}
              </Grid>
            ))}
        </Ant.Col>
      </Wrapper>
    );
  }

  _fetchMyCourseList = async () => {
    this.setState({ loading: true });

    try {
      const { sort, category } = this.state;
      let params = {
        sort,
        category,
      };

      const courses = await Catalog.Actions.fetchMyCourseList(params);

      this.setState({ courses });
    } catch (err) {
      console.warn("fetchMyCourseList", err);
    }

    this.setState({ loading: false });
  };
}

const Wrapper = styled.div`
  margin: 20px;

  & h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
    font-size: 21px;
  }

  & .title {
    padding: 8px 10px;
    font-size: 16px;
    background-color: ${Theme.colors.main};
    color: #fff;
    margin-bottom: 20px;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #f4f4f4;
`;

export default connect(null, ActionCreator)(Cart.withConsumer(MyCourseList));
