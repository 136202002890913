import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { DialogWrapper, Input, Button, Selector, Title } from "./DialogWidgets";
import * as Icon from "./Icon";

class RequestMonthlyDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configData: this.props.profile.monthly_data,
    };
  }

  render() {
    let { closeDialog, editable } = this.props;
    let { configData } = this.state;
    let data = this._parseData(configData);
    console.log("--configData->", configData);
    console.log("this.props.profile->", this.props.profile);
    return (
      <Ant.Modal
        visible={true}
        title={
          editable ? (
            <Title>申請成為月結會員</Title>
          ) : (
            <Title>月結服務申請表</Title>
          )
        }
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              取消
            </Button>
            {editable && (
              <Button css="margin: 10px;" onClick={this._submit}>
                確定
              </Button>
            )}
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper labelWidth={"145px"}>
          <div className="content">
            <div className="row">
              <h4>企業會員名稱 / 公司</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.ent_name ? data.ent_name : ""}
                  onChange={e =>
                    this._onFieldChange("ent_name", e.target.value)
                  }
                />
              ) : (
                <p>{data.ent_name ? data.ent_name : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>抬頭</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.company_title ? data.company_title : ""}
                  onChange={e =>
                    this._onFieldChange("company_title", e.target.value)
                  }
                />
              ) : (
                <p>{data.company_title ? data.company_title : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>統一編號</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.gui_number ? data.gui_number : ""}
                  onChange={e =>
                    this._onFieldChange("gui_number", e.target.value)
                  }
                />
              ) : (
                <p>{data.gui_number ? data.gui_number : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>電子信箱</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.email ? data.email : ""}
                  onChange={e => this._onFieldChange("email", e.target.value)}
                />
              ) : (
                <p>{data.email ? data.email : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>產業類型</h4>
              <Selector
                disabled={!editable}
                value={data && data.industry_type && data.industry_type}
                onChange={e =>
                  this._onFieldChange("industry_type", e.target.value)
                }
              >
                <option value="文化創意／藝文相關">文化創意／藝文相關</option>
                <option value="印刷同業">印刷同業</option>
                <option value="廣告行銷">廣告行銷</option>
                <option value="設計公司">設計公司</option>
                <option value="個人工作室／SOHO">個人工作室／SOHO</option>
                <option value="其他">其他</option>
              </Selector>
            </div>
            <div className="row">
              <h4>聯絡電話</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.ent_tel ? data.ent_tel : ""}
                  onChange={e => this._onFieldChange("ent_tel", e.target.value)}
                />
              ) : (
                <p>{data.ent_tel ? data.ent_tel : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>傳真號碼</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.fax ? data.fax : ""}
                  onChange={e => this._onFieldChange("fax", e.target.value)}
                />
              ) : (
                <p>{data.fax ? data.fax : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>聯絡地址</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.address ? data.address : ""}
                  onChange={e => this._onFieldChange("address", e.target.value)}
                />
              ) : (
                <p>{data.address ? data.address : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>發票形式</h4>
              <Selector
                disabled={!editable}
                value={data && data.receipt_type && data.receipt_type}
                onChange={e =>
                  this._onFieldChange("receipt_type", e.target.value)
                }
              >
                <option value="二聯">二聯</option>
                <option value="三聯">三聯</option>
              </Selector>
            </div>
            <div className="row">
              <h4>公司負責人 手機 / 電話</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.principal_tel ? data.principal_tel : ""}
                  onChange={e =>
                    this._onFieldChange("principal_tel", e.target.value)
                  }
                />
              ) : (
                <p>{data.principal_tel ? data.principal_tel : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>聯絡人 手機 / 電話</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.contact_tel ? data.contact_tel : ""}
                  onChange={e =>
                    this._onFieldChange("contact_tel", e.target.value)
                  }
                />
              ) : (
                <p>{data.contact_tel ? data.contact_tel : "--"}</p>
              )}
            </div>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }

  _parseData = data => {
    const defaultValue = {};
    let parsed = null;
    try {
      parsed = JSON.parse(data);
      if (Object.keys(parsed).length < 1) {
        parsed = defaultValue;
      }
    } catch (ex) {
      parsed = defaultValue;
    }
    return parsed;
  };

  _submit = () => {
    let { configData } = this.state;
    let { requestToBeMonthly, appActions, closeDialog } = this.props;

    appActions
      .editMyProfile({ monthly_data: configData })
      .then(requestToBeMonthly)
      .then(closeDialog)
      .catch(err => console.warn(err));
  };

  _onFieldChange = (field, value) => {
    let parsed = this._parseData(this.state.configData);

    parsed[field] = value;
    this.setState({
      configData: JSON.stringify(parsed),
    });
  };
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(RequestMonthlyDialog);
