import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Cart from "../Contexts/CartContext";
import OrderTable from "./OrderTable";
import * as Ant from "antd";
import CustomOrderModal from "../Modals/CustomOrderModal";
import * as Theme from "../Theme";

const FilterStatusTypes = {
  /* 全部, (檔案不合格), 待付款, 已完成, 月結*/
  NORMAL: 0,
  ORDER_ITEM_REJECTED: 1,
  WAITING_OR_PROCESSING: 2,
  SUCCESS: 3,
  MONTHLY: 4,
};
class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      orders: [],
      openCustomOrderModal: false,
    };
  }

  componentDidMount() {
    this._getOrdersByQuery();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.type !== this.props.type) {
      this._getOrdersByQuery();
    }
  }

  render() {
    let { orders, loading, openCustomOrderModal } = this.state;
    let { navActions, profile, type } = this.props;

    return (
      <Wrapper>
        <h2>
          {
            [
              "全部訂單",
              "檔案未合格訂單",
              "待付款訂單",
              "付款已完成訂單",
              "月結訂單",
            ][type]
          }
        </h2>

        {profile && profile.staff_type === "staff" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            <Ant.Button
              type="primary"
              onClick={() => this.setState({ openCustomOrderModal: true })}
            >
              自訂商品
            </Ant.Button>
          </div>
        )}

        <OrderTable
          loading={loading}
          orders={orders}
          mounted={this.mounted}
          onItemClick={order => navActions.push(`/order?id=${order.id}`)}
        />

        <CustomOrderModal
          open={openCustomOrderModal}
          onClose={() => this.setState({ openCustomOrderModal: false })}
        />
      </Wrapper>
    );
  }

  _getOrdersByQuery = async () => {
    let { type } = this.props;
    this.setState({ loading: true });

    let payment_status = (() => {
      if (type == FilterStatusTypes.SUCCESS) {
        return `success`;
      } else if (type == FilterStatusTypes.WAITING_OR_PROCESSING) {
        return `waiting,processing`;
      } else {
        return "";
      }
    })();

    let payment_type = (() => {
      if (type == FilterStatusTypes.MONTHLY) {
        return `monthly`;
      } else {
        return "";
      }
    })();

    try {
      let orders = await Cart.Actions.fetchOrders({
        payment_status,
        payment_type,
      });

      if (type === FilterStatusTypes.ORDER_ITEM_REJECTED) {
        orders = orders.filter(order => !order.is_item_accepted);
      }

      this.setState({ orders });
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ loading: false });
    this.mounted = true;
    this.forceUpdate();
  };
}

const Wrapper = styled.div`
  margin: 20px;
  h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
  }
  @media screen and (max-width: ${Theme.breakpoints.sm}px) {
    padding: 20px;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(OrderList);

export { FilterStatusTypes };
