import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

const Wrapper = styled.div`
  margin: 20px 0;
  margin-right: 8px;

  & > p {
    font-size: 14px;
    color: #575757;
    margin-bottom: 10px;

    & > span {
      color: #f92b2b;
    }
  }
  ${props => props.css}
`;

class TextInputField extends Component {
  render() {
    const { label, isRequired, value, onChange, placeholder, css } = this.props;

    return (
      <Wrapper css={css}>
        <p>
          {label}
          {/* {isRequired && <span>*</span>} */}
        </p>
        <Input
          type="text"
          placeholder={
            placeholder && isRequired ? placeholder + "*" : placeholder
          }
          value={value}
          extraCss={`border-radius: 5px;`}
          onChange={e => onChange(e.target.value)}
        />
      </Wrapper>
    );
  }
}

const Input = styled.input`
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
  height: 40px;
  outline: none;
  color: #575757;
  border: 1px solid #dadada;

  &:focus {
    outline: 1px solid #c89664;
    outline-offset: -1px;
  }

  ${props => props.extraCss};
`;

export default TextInputField;
