import React from "react";
import { connect } from "react-redux";
import * as Ant from "antd";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";

class MonthlyApplyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configData: this.props.profile.monthly_data,
    };
  }

  render() {
    let { profile } = this.props;
    let { configData } = this.state;
    if (!profile) {
      return null;
    }
    let data = this._parseData(configData);
    return (
      <Wrapper>
        <div className="content">
          <div className="row">
            <h4>企業會員名稱 / 公司</h4>
            <Input
              type="text"
              value={data.ent_name ? data.ent_name : ""}
              onChange={e => this._onFieldChange("ent_name", e.target.value)}
            />
          </div>
          <div className="row">
            <h4>抬頭</h4>
            <Input
              type="text"
              value={data.company_title ? data.company_title : ""}
              onChange={e =>
                this._onFieldChange("company_title", e.target.value)
              }
            />
          </div>
          <div className="row">
            <h4>統一編號</h4>
            <Input
              type="text"
              value={data.gui_number ? data.gui_number : ""}
              onChange={e => this._onFieldChange("gui_number", e.target.value)}
            />
          </div>
          <div className="row">
            <h4>電子信箱</h4>
            <Input
              type="text"
              value={data.email ? data.email : ""}
              onChange={e => this._onFieldChange("email", e.target.value)}
            />
          </div>
          <div className="row">
            <h4>產業類型</h4>
            <Selector
              value={data && data.industry_type && data.industry_type}
              onChange={e =>
                this._onFieldChange("industry_type", e.target.value)
              }
            >
              <option value="文化創意／藝文相關">文化創意／藝文相關</option>
              <option value="印刷同業">印刷同業</option>
              <option value="廣告行銷">廣告行銷</option>
              <option value="設計公司">設計公司</option>
              <option value="個人工作室／SOHO">個人工作室／SOHO</option>
              <option value="其他">其他</option>
            </Selector>
          </div>
          <div className="row">
            <h4>聯絡電話</h4>
            <Input
              type="text"
              value={data.ent_tel ? data.ent_tel : ""}
              onChange={e => this._onFieldChange("ent_tel", e.target.value)}
            />
          </div>
          <div className="row">
            <h4>傳真號碼</h4>
            <Input
              type="text"
              value={data.fax ? data.fax : ""}
              onChange={e => this._onFieldChange("fax", e.target.value)}
            />
          </div>
          <div className="row">
            <h4>聯絡地址</h4>
            <Input
              type="text"
              value={data.address ? data.address : ""}
              onChange={e => this._onFieldChange("address", e.target.value)}
            />
          </div>
          <div className="row">
            <h4>發票形式</h4>
            <Selector
              value={data && data.receipt_type && data.receipt_type}
              onChange={e =>
                this._onFieldChange("receipt_type", e.target.value)
              }
            >
              <option value="二聯">二聯</option>
              <option value="三聯">三聯</option>
            </Selector>
          </div>
          <div className="row">
            <h4>公司負責人 手機 / 電話</h4>
            <Input
              type="text"
              value={data.principal_tel ? data.principal_tel : ""}
              onChange={e =>
                this._onFieldChange("principal_tel", e.target.value)
              }
            />
          </div>
          <div className="row">
            <h4>聯絡人 手機 / 電話</h4>
            <Input
              type="text"
              value={data.contact_tel ? data.contact_tel : ""}
              onChange={e => this._onFieldChange("contact_tel", e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Ant.Button
            type="primary"
            onClick={this._submit}
            style={{
              margin: 10,
              flexGrow: 0,
              backgroundColor: "#c89664",
              border: 0,
            }}
          >
            送出申請
          </Ant.Button>
        </div>
      </Wrapper>
    );
  }

  _parseData = data => {
    const defaultValue = {};
    let parsed = null;
    try {
      parsed = JSON.parse(data);
      if (Object.keys(parsed).length < 1) {
        parsed = defaultValue;
      }
    } catch (ex) {
      parsed = defaultValue;
    }
    return parsed;
  };

  _submit = () => {
    let { configData } = this.state;
    let { appActions } = this.props;

    appActions
      .editMyProfile({ monthly_data: configData, req_ent_monthly: true })
      .catch(err => console.warn(err));
  };

  _onFieldChange = (field, value) => {
    let parsed = this._parseData(this.state.configData);

    parsed[field] = value;
    this.setState({
      configData: JSON.stringify(parsed),
    });
  };
}

const Wrapper = styled.div`
  background-color: white;

  & > .subtitle {
    font-size: 20px;
    padding: 10px 40px;
    letter-spacing: 2px;
  }

  & > .content {
    padding: 31px 23px;
  }

  & > .border-content {
    padding: 30px 40px;
    border-bottom: 1px solid #eaeaea;
  }

  & .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    & > p {
      font-size: 13px;
      margin: 0;
      color: #19233b;
    }

    & > h4 {
      display: block;
      margin-right: 10px;
      width: 160px;
      color: #343434;
    }
  }
`;

const Input = styled.input`
  width: 200px;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  padding: 8px 10px;
  outline: none;
  color: #101d1f;

  :focus {
    outline: none;
  }
`;

const Selector = styled.select`
  height: 30px;
  width: 200px;
  background-color: #fff;
  border-radius: 6px;

  :focus {
    outline: none;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(MonthlyApplyForm);
