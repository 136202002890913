import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
// import OrderListItem from "./CreditOrderListItem";
import InvoiceInfo from "./InvoiceInfo";
import OrderTable, { TableTypes } from "./OrderTable";
import InvoiceForm from "./InvoiceForm";
import * as Cart from "../Contexts/CartContext";
import { isEnt } from "../Domain/RoleValidator";
import * as Theme from "../Theme";
import {
  isPersonCertificatedCode,
  isMobileVehicleCode,
  isCompanyId,
  isLoveCode,
} from "../Domain/FormatValidator";
const validator = require("validator");

const TABS = [
  { tab: "TO_DEPOSIT", display: "帳戶儲值" },
  { tab: "DEPOSIT_RECORD", display: "儲值紀錄" },
  { tab: "CREDIT_RECORD", display: "點數交易紀錄" },
];

const creditUiTypes = {
  TO_DEPOSIT: 0,
  DEPOSIT_RECORD: 1,
  CREDIT_RECORD: 2,
};

class CreditPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiTab: "TO_DEPOSIT",
      afterClickNext: false,
      orders: [],
      loading: false,
      credits: 3000,
      customizeCredits: 0,
      feedbackData: {
        limit: null,
        value: null,
        expire_date: null,
      },
      entFeedbackData: {
        limit: null,
        value: null,
        expire_date: null,
      },
      config: {
        invoiceConfig: null,
      },
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this._loadConfigFromServer();
    this._getFeedBackPromotion();

    try {
      const orders = await Cart.Actions.fetchOrders();
      this.setState({ orders });
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ loading: false });
    this.mounted = true;
    this.forceUpdate();
  }
  render() {
    let {
      uiTab,
      orders,
      loading,
      credits,
      customizeCredits,
      feedbackData,
      entFeedbackData,
      config,
      afterClickNext,
    } = this.state;
    let { profile, uiType, cart, appActions, navActions } = this.props;
    let finalBuyCredit =
      credits != 0 ? Number(credits) : Number(customizeCredits).toFixed();
    let totalCredit =
      Number(profile.credits) +
      Number(this._getFeedBackAmount()) +
      Number(finalBuyCredit);
    totalCredit = Number(totalCredit).toFixed();

    return (
      <Wrapper>
        {(() => {
          switch (uiType) {
            case creditUiTypes.TO_DEPOSIT:
              return (
                <>
                  <h2>帳戶儲值</h2>
                  <div className="form-wrapper">
                    <div className="title">選擇加值面額</div>

                    <Container>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p style={{ fontWeight: "bold" }}>{`帳戶剩餘點數：`}</p>
                        <p style={{ color: Theme.colors.main }}>
                          {profile.credits}點
                        </p>
                      </div>
                      <div>
                        <p>加值點數費用</p>
                        <select
                          value={credits}
                          onChange={e =>
                            this.setState({ credits: e.target.value })
                          }
                        >
                          <option value="3000">3000元</option>
                          <option value="5000">5000元</option>
                          <option value="10000">10000元</option>
                          <option value="20000">20000元</option>
                          <option value="30000">30000元</option>
                          <option value="50000">50000元</option>
                          <option value="100000">100000元</option>
                          <option value="0">自訂金額</option>
                        </select>
                        {credits == 0 && (
                          <>
                            <p>自訂金額(需大於100)</p>
                            <input
                              type="number"
                              value={customizeCredits}
                              onChange={e =>
                                this.setState({
                                  customizeCredits: e.target.value,
                                })
                              }
                            />
                          </>
                        )}
                      </div>
                      {!(customizeCredits !== 0 && customizeCredits < 3000) && (
                        <p>
                          {isEnt(profile)
                            ? `預儲優惠 : 企業vip買點數儲值即贈送 ${entFeedbackData.value *
                                100} %點數回饋，最多可回饋${
                                entFeedbackData.limit
                              }點`
                            : `預儲優惠 : vip買點數儲值即贈送 ${feedbackData.value *
                                100} %點數回饋，最多可回饋${
                                feedbackData.limit
                              }點`}
                        </p>
                      )}
                      <p>{`加值點數：${finalBuyCredit}點`}</p>
                      <p>{`贈送點數：${Number(
                        this._getFeedBackAmount()
                      ).toFixed()}點`}</p>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p style={{ margin: 0 }}>{`加值後帳戶總點數：`}</p>
                        <p style={{ color: Theme.colors.main, margin: 0 }}>
                          {`${totalCredit}點`}
                        </p>
                      </div>
                    </Container>
                    <div className="title">發票資訊</div>
                    <Container>
                      {config.invoiceConfig && (
                        <InvoiceForm
                          doValidate={afterClickNext}
                          config={config.invoiceConfig}
                          setConfig={invoice_config => {
                            this.setState({
                              afterClickNext: false,
                              config: {
                                ...config,
                                invoiceConfig: invoice_config,
                              },
                            });
                          }}
                        />
                      )}
                      {/* {config && (
                        <InvoiceInfo
                          isEditable
                          showTitle={false}
                          config={config}
                          updateConfig={data => {
                            console.log("nextData", data);
                            this.setState({ config: data });
                          }}
                        />
                      )} */}
                    </Container>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 20,
                      }}
                    >
                      <Button onClick={this._submit}>付款去</Button>
                    </div>
                  </div>
                </>
              );
            case creditUiTypes.DEPOSIT_RECORD:
              return (
                <>
                  <h2>儲值記錄</h2>
                  <OrderTable
                    type={TableTypes.BUY_CREDITS_ORDER}
                    loading={loading}
                    orders={orders.filter(
                      order => order.order_type === "credit"
                    )}
                    mounted={this.mounted}
                    onItemClick={order =>
                      navActions.push(`/order?id=${order.id}`)
                    }
                  />
                </>
              );
            case creditUiTypes.CREDIT_RECORD:
              return (
                <>
                  <h2>點數交易記錄</h2>
                  <OrderTable
                    type={TableTypes.PAY_BY_CREDIT}
                    loading={loading}
                    orders={orders.filter(
                      order => order.payment_type == "credits"
                    )}
                    mounted={this.mounted}
                    onItemClick={order =>
                      navActions.push(`/order?id=${order.id}`)
                    }
                  />
                </>
              );
          }
        })()}
      </Wrapper>
    );
  }

  _loadConfigFromServer = async () => {
    try {
      let resp = await Cart.Actions.fetchCart();
      if (resp && resp.config.invoiceConfig) {
        this.setState({ config: resp.config });
      } else {
        this.setState({
          config: {
            invoiceConfig: {
              invoiceType: "invoice_two_copies",
              invoiceDetail: "",

              citizen_personal_certificate_code: "", //自然人憑證條碼(16碼)
              mobile_vehicle_code: "", //手機載具(8碼)
              donate_foundation: "",
              gui_number: "",
              invoice_title: "",
            },
          },
        });
      }
      return resp;
    } catch (ex) {
      console.warn("fetchCart error", ex);
    }
  };

  _getFeedBackAmount = () => {
    let {
      credits,
      customizeCredits,
      feedbackData,
      entFeedbackData,
    } = this.state;
    let { profile } = this.props;
    let feedbackAmount = 0;
    if (customizeCredits !== 0 && customizeCredits < 3000) {
      // 促銷中心最低買 3000才有回饋
      return 0;
    }
    if (isEnt(profile)) {
      if (
        credits * entFeedbackData.value > entFeedbackData.limit ||
        customizeCredits * entFeedbackData.value > entFeedbackData.limit
      ) {
        feedbackAmount = entFeedbackData.limit;
      } else {
        feedbackAmount =
          credits != 0
            ? credits * entFeedbackData.value
            : customizeCredits * entFeedbackData.value;
      }
    } else {
      if (
        credits * feedbackData.value > feedbackData.limit ||
        customizeCredits * feedbackData.value > feedbackData.limit
      ) {
        feedbackAmount = feedbackData.limit;
      } else {
        feedbackAmount =
          credits != 0
            ? credits * feedbackData.value
            : customizeCredits * feedbackData.value;
      }
    }
    return feedbackAmount;
  };

  _getFeedBackPromotion = async () => {
    try {
      let feedback_data = await Cart.Actions.getCurrentFeedbackPromotionByUserType(
        "vip"
      );
      this.setState({
        feedbackData: {
          limit: feedback_data.limit,
          value: feedback_data.value,
          expire_date: feedback_data.expire_date,
        },
      });
      let ent_feedback_data = await Cart.Actions.getCurrentFeedbackPromotionByUserType(
        "ent_vip"
      );
      this.setState({
        entFeedbackData: {
          limit: ent_feedback_data.limit,
          value: ent_feedback_data.value,
          expire_date: ent_feedback_data.expire_date,
        },
      });
    } catch (ex) {
      console.warn(ex);
    }
  };
  _generateOrderStep = order => {
    let { selectedOrder } = this.state;
    if (order.payment_status === "waiting") {
      return 1; //訂單建立
    } else if (order.payment_status !== "waiting") {
      return 2; //付款
    } else if (
      order.payment_status === "success" ||
      order.is_item_accepted == false
    ) {
      return 3;
    }
    return;
  };

  _submit = async () => {
    let { appActions, navActions } = this.props;
    let { credits, customizeCredits, config } = this.state;
    if (credits == 0) {
      credits = customizeCredits;
    }
    this.setState({ afterClickNext: true });
    if (this._checkDataValidated()) {
      try {
        let resp = await Cart.Actions.editConfig(config);
        console.log("config resp--->", resp);
        let order = await Cart.Actions.createCreditsOrder(credits);
        navActions.push(`/order?id=${order.id}`);
      } catch (ex) {
        console.warn(ex);
      }
    }
  };

  _checkDataValidated = () => {
    let { config } = this.state;
    let { invoiceConfig } = config;
    let {
      invoice_type,
      invoice_subtype,
      citizen_personal_certificate_code,
      mobile_vehicle_code,
      gui_number,
      company_title,
      donate_foundation_gui,
      love_code,
    } = invoiceConfig;
    let { isEmpty, isEmail, isNumeric } = validator;

    if (invoice_type === "two_copies") {
      if (invoice_subtype === "citizen_personal_certificate") {
        if (
          isEmpty(citizen_personal_certificate_code) ||
          !isPersonCertificatedCode(citizen_personal_certificate_code)
        ) {
          return false;
        }
      } else if (invoice_subtype === "mobile_vehicle") {
        if (
          isEmpty(mobile_vehicle_code) ||
          !isMobileVehicleCode(mobile_vehicle_code)
        ) {
          return false;
        }
      }
    }

    if (invoice_type === "three_copies") {
      if (isEmpty(gui_number) || !isCompanyId(gui_number)) {
        return false;
      }
      if (isEmpty(company_title)) {
        return false;
      }
    }

    if (invoice_type === "donate") {
      if (
        isEmpty(donate_foundation_gui) ||
        !isCompanyId(donate_foundation_gui)
      ) {
        return false;
      }
      if (isEmpty(love_code) || !isLoveCode(love_code)) {
        return false;
      }
    }

    return true;
  };
}

const Wrapper = styled.div`
  margin: 20px;

  & h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
    font-size: 21px;
  }

  & > .form-wrapper {
    border: 0.5px solid #ccc;
    background-color: #fff;
    padding: 20px;
  }

  & .title {
    padding: 8px 10px;
    font-size: 16px;
    background-color: ${Theme.colors.main};
    color: #fff;
    margin-bottom: 20px;
  }
`;
const Container = styled.div`
  padding: 30px 25px;
  border-bottom: 1px solid #e1e1e1;

  & > h4 {
    font-size: 16px;
    color: #444444;
    font-weight: 500;
    margin-bottom: 15px;
  }

  & > p {
    font-size: 14px;
    color: #444444;
    margin-bottom: 15px;
  }

  & > div {
    display: flex;
    margin-bottom: 15px;

    & > p {
      margin-right: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #444444;
    }

    & > select {
      margin-right: 30px;
      height: 40px;
    }

    & > input {
      border-radius: 5px;
      border: 1px solid #a6a6a6;
      padding-left: 15px;
    }

    *:focus {
      outline: none;
    }
  }

  & > .brown {
    color: ${Theme.colors.main};
  }
`;

const CreditBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Theme.colors.main};
  margin: 20px;
  padding: 25px 45px;

  p {
    color: #444444;
    font-size: 16px;
  }

  & > .credit {
    font-size: 22px;
    color: ${Theme.colors.main};
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: ${props => props.color || "#3CC2A1"};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;

  ${props => props.css}
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    // cart: Selectors.getCartData(state),
  }),
  ActionCreator
)(CreditPanel);

export { creditUiTypes };
