import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Cart from "../Contexts/CartContext";
import Form from "./Form";

const Wrapper = styled.div``;

class ProfileForm extends React.Component {
  render() {
    let { profile, appActions } = this.props;
    if (!profile) {
      return null;
    }
    console.log("profile", profile);

    let generalForm = [
      { name: "name", type: "text", label: "你的名稱", required: true },
      { name: "company", type: "text", label: "公司名稱", required: true },
      { name: "phone", type: "text", label: "聯絡電話" },
      { name: "address", type: "textarea", label: "聯絡地址" },
      { name: "email", type: "text", label: "電子信箱" },
    ];

    return (
      <Wrapper>
        <Form
          model={{
            fields: generalForm,
          }}
          instance={profile}
          fnUpdate={values => {
            appActions.editMyProfile(values);
            Cart.Actions.editConfig(values);
          }}
        />
      </Wrapper>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ProfileForm);
