import React, { Component } from "react";
import styled from "styled-components";
import RadioSelectorGroupField from "./RadioSelectorGroupField";
import TextInputField from "./TextInputField";

let supportedInvoiceType = [
  "invoice_two_copies",
  "invoice_three_copies",
  "invoice_donate",
];
let supportedTwoCopiesInvoiceType = [
  "citizen_personal_certificate",
  "mobile_vehicle",
  "ezpay_vehicle",
];

const ALL_SUPPORTED_INVOICE_TYPE = {
  invoice_two_copies: {
    label: "二聯式發票",
    value: "invoice_two_copies",
  },
  invoice_three_copies: {
    label: "三聯式發票",
    value: "invoice_three_copies",
  },
  invoice_donate: {
    label: "捐贈發票",
    value: "invoice_donate",
  },
};

const INVOICE_TWO_COPIES_TYPES = {
  citizen_personal_certificate: {
    label: "自然人憑證條碼",
    value: "citizen_personal_certificate",
  },
  mobile_vehicle: {
    label: "手機載具",
    value: "mobile_vehicle",
  },
  ezpay_vehicle: {
    label: "ezPay 電子發票載具",
    value: "ezpay_vehicle",
  },
};

class InvoiceInfo extends Component {
  render() {
    let {
      config,
      enableTwoCopies = true,
      updateConfig = () => 0,
      isEditable = false,
      showTitle = true,
    } = this.props;
    let options = supportedInvoiceType.map(t => ALL_SUPPORTED_INVOICE_TYPE[t]);

    let invoice_two_copies_options = supportedTwoCopiesInvoiceType.map(
      t => INVOICE_TWO_COPIES_TYPES[t]
    );
    let data = parseInvoice({ cart: config });
    return (
      <Wrapper>
        {showTitle && <p className="title">發票資訊</p>}
        {isEditable ? (
          <RadioSelectorGroupField
            horizontal
            unableOption={(!enableTwoCopies && "invoice_two_copies") || ""}
            value={config.invoiceConfig.invoiceType}
            onChange={invoiceType =>
              updateConfig({
                ...config,
                invoiceConfig: { ...config.invoiceConfig, invoiceType },
              })
            }
            options={options}
          />
        ) : (
          <span className="text margin-top-10">
            {data.map((item, idx) => (
              <Row key={idx}>
                <Label>{item.label}</Label>
                <Value>{item.value}</Value>
              </Row>
            ))}
          </span>
        )}

        {enableTwoCopies &&
          config.invoiceConfig.invoiceType == "invoice_two_copies" && (
            <div style={{ marginLeft: 20, marginTop: 10 }}>
              <RadioSelectorGroupField
                value={config.invoiceConfig.invoiceDetail}
                onChange={invoiceDetail =>
                  updateConfig({
                    ...config,
                    invoiceConfig: { ...config.invoiceConfig, invoiceDetail },
                  })
                }
                options={invoice_two_copies_options}
              />
              {config.invoiceConfig.invoiceDetail ==
                "citizen_personal_certificate" && (
                <TextInputField
                  placeholder="請輸入自然人憑證條碼16碼"
                  value={
                    config.invoiceConfig.citizen_personal_certificate_code || ""
                  }
                  onChange={citizen_personal_certificate_code =>
                    updateConfig({
                      ...config,
                      invoiceConfig: {
                        ...config.invoiceConfig,
                        citizen_personal_certificate_code,
                      },
                    })
                  }
                />
              )}
              {config.invoiceConfig.invoiceDetail == "mobile_vehicle" && (
                <TextInputField
                  placeholder="請輸入手機載具條碼8碼"
                  value={config.invoiceConfig.mobile_vehicle_code || ""}
                  onChange={mobile_vehicle_code =>
                    updateConfig({
                      ...config,
                      invoiceConfig: {
                        ...config.invoiceConfig,
                        mobile_vehicle_code,
                      },
                    })
                  }
                />
              )}
              {config.invoiceConfig.invoiceDetail == "ezpay_vehicle" && (
                <TextInputField
                  placeholder="請輸入ezpay載具條碼"
                  value={config.invoiceConfig.ezpay_vehicle_code || ""}
                  onChange={ezpay_vehicle_code =>
                    updateConfig({
                      ...config,
                      invoiceConfig: {
                        ...config.invoiceConfig,
                        ezpay_vehicle_code,
                      },
                    })
                  }
                />
              )}
            </div>
          )}

        {config.invoiceConfig.invoiceType == "invoice_donate" && (
          // TODO: donate foundations list selector
          <TextInputField
            label="請輸入愛心捐贈碼"
            placeholder="請輸入愛心捐贈碼"
            value={config.invoiceConfig.donate_foundation || ""}
            onChange={donate_foundation =>
              updateConfig({
                ...config,
                invoiceConfig: { ...config.invoiceConfig, donate_foundation },
              })
            }
          />
        )}

        {config.invoiceConfig.invoiceType == "invoice_three_copies" && (
          <div style={{ display: "flex" }}>
            <TextInputField
              label="統一編號"
              placeholder="統一編號"
              value={config.invoiceConfig.gui_number || ""}
              onChange={gui_number =>
                updateConfig({
                  ...config,
                  invoiceConfig: { ...config.invoiceConfig, gui_number },
                })
              }
            />
            <TextInputField
              label="發票抬頭"
              placeholder="發票抬頭"
              value={config.invoiceConfig.invoice_title || ""}
              onChange={invoice_title =>
                updateConfig({
                  ...config,
                  invoiceConfig: { ...config.invoiceConfig, invoice_title },
                })
              }
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & .margin-top-10 {
    margin-top: 10px;
  }

  & > .title {
    font-weight: bold;
    border-top: 0.5px solid #cccccc;
    padding-top: 30px;
    margin-top: 52px;
    font-size: 22px;
    color: #4a4a4a;
  }

  & > .subtitle {
    margin-top: 52px;
    font-weight: bold;
    font-size: 14px;
    color: #575757;
  }

  & > .hint {
    font-size: 14px;
    color: #a4a4a4;
  }

  & .text {
    font-size: 16px;
    color: grey;
    display: block;
    padding-left: 10px;
    border-left: 3px solid lightgrey;
  }
`;

export default InvoiceInfo;
