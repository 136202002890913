import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import ChangePwForm from "./ChangePwForm";
import * as Theme from "../Theme";

const Wrapper = styled.div`
  margin: 20px;

  & h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
    font-size: 21px;
  }

  & .hint {
    margin-left: 10px;
    font-size: 14px;
    color: ${Theme.colors.main};
  }

  & > .form-wrapper {
    border: 0.5px solid #ccc;
    background-color: #fff;
    padding: 20px;
  }

  & .title {
    padding: 8px 10px;
    font-size: 16px;
    background-color: ${Theme.colors.main};
    color: #fff;
    margin-bottom: 20px;
  }
`;

const Button = styled.div`
  padding: 10px 30px;
  background-color: ${props => props.color || `${Theme.colors.main}`};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;

  ${props => props.css}
`;

class ProfilePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: null,
    };
  }

  render() {
    let { config } = this.state;
    return (
      <Wrapper>
        <h2>修改登入密碼</h2>
        <div className="form-wrapper">
          <div className="title">重設登入密碼</div>
          <p className="hint">*若您使用Facebook/Google登入，將無法修改密碼</p>
          <ChangePwForm /> {/* use old sensation repo comps below this layer */}
        </div>
      </Wrapper>
    );
  }

  _submit = async () => {
    let { config } = this.state;
    try {
    } catch (ex) {
      console.warn(ex);
    }
  };
}

export default connect(null, ActionCreator)(ProfilePanel);
