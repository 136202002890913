import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Ant from "antd";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { DialogWrapper, Input, Button, Selector, Title } from "./DialogWidgets";
import * as Icon from "./Icon";
const validator = require("validator");

class RequestEntDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      configData: this.props.profile.data,
      doValidate: false,
    };
  }

  render() {
    let { closeDialog, editable } = this.props;
    let { configData, doValidate } = this.state;
    let { isEmpty, isNumeric } = validator;
    let data = this._parseData(configData);
    return (
      <Ant.Modal
        visible={true}
        title={
          editable ? (
            <Title>申請成為企業會員</Title>
          ) : (
            <Title>企業會員申請表</Title>
          )
        }
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              取消
            </Button>
            {editable && (
              <Button css="margin: 10px;" onClick={this._submit}>
                確定
              </Button>
            )}
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper>
          <div className="content">
            <div className="row">
              <h4>公司名稱*</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.ent_name ? data.ent_name : ""}
                  onChange={e =>
                    this._onFieldChange("ent_name", e.target.value)
                  }
                />
              ) : (
                <p>{data.ent_name ? data.ent_name : "--"}</p>
              )}
              {doValidate &&
                isEmpty(data.ent_name) &&
                ErrorHint({ message: "公司名稱不能為空" })}
            </div>
            <div className="row">
              <h4>統一編號*</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.gui_number ? data.gui_number : ""}
                  onChange={e =>
                    this._onFieldChange("gui_number", e.target.value)
                  }
                />
              ) : (
                <p>{data.gui_number ? data.gui_number : "--"}</p>
              )}
              {doValidate &&
                isEmpty(data.gui_number) &&
                ErrorHint({ message: "統一編號不能為空" })}
            </div>
            <div className="row">
              <h4>公司電話*</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.ent_tel ? data.ent_tel : ""}
                  onChange={e => this._onFieldChange("ent_tel", e.target.value)}
                />
              ) : (
                <p>{data.ent_tel ? data.ent_tel : "--"}</p>
              )}
              {doValidate &&
                (() => {
                  if (isEmpty(data.ent_tel)) {
                    console.log("hii");
                    return ErrorHint({ message: "公司電話不能為空" });
                  } else if (!isNumeric(data.ent_tel)) {
                    return ErrorHint({ message: "請確認公司電話格式" });
                  }
                })()}
            </div>
            <div className="row">
              <h4>分機</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.tel_ext ? data.tel_ext : ""}
                  onChange={e => this._onFieldChange("tel_ext", e.target.value)}
                />
              ) : (
                <p>{data.tel_ext ? data.tel_ext : "--"}</p>
              )}
            </div>
            <div className="row">
              <h4>公司地址*</h4>
              {editable ? (
                <Input
                  type="text"
                  value={data.ent_address ? data.ent_address : ""}
                  onChange={e =>
                    this._onFieldChange("ent_address", e.target.value)
                  }
                />
              ) : (
                <p>{data.ent_address ? data.ent_address : "--"}</p>
              )}
              {doValidate &&
                isEmpty(data.ent_address) &&
                ErrorHint({ message: "公司地址不能為空" })}
            </div>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }

  _parseData = data => {
    const defaultValue = {};
    let parsed = null;
    try {
      parsed = JSON.parse(data);
      if (Object.keys(parsed).length < 1) {
        parsed = defaultValue;
      }
    } catch (ex) {
      parsed = defaultValue;
    }
    return parsed;
  };

  _checkDataValidated = () => {
    let { isEmpty, isNumeric } = validator;
    let { configData } = this.state;
    let data = this._parseData(configData);
    this.setState({ doValidate: true });
    if (
      isEmpty(data.ent_name) ||
      isEmpty(data.gui_number) ||
      isEmpty(data.ent_tel) ||
      isEmpty(data.ent_address)
    ) {
      return false;
    } else if (!isNumeric(data.gui_number) || !isNumeric(data.ent_tel)) {
      return false;
    }
    return true;
  };

  _submit = () => {
    let { configData } = this.state;
    let { requestToBeEnt, appActions, closeDialog } = this.props;
    if (this._checkDataValidated()) {
      appActions
        .editMyProfile({ data: configData })
        .then(requestToBeEnt)
        .then(closeDialog)
        .catch(err => console.warn(err));
    }
  };

  _onFieldChange = (field, value) => {
    let parsed = this._parseData(this.state.configData);

    parsed[field] = value;
    this.setState({
      configData: JSON.stringify(parsed),
      doValidate: false,
    });
  };
}

const ErrorHint = ({ message }) => {
  return <Alert message={message} type="warning" showIcon />;
};

const Alert = styled(Ant.Alert)`
  margin-left: 5px;

  .ant-alert-message {
    color: #6b6b6b;
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(RequestEntDialog);
