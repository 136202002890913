import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import MonthlyApplyForm from "./MonthlyApplyForm";
import InvoiceInfo from "./InvoiceInfo";
import * as Cart from "../Contexts/CartContext";

const Wrapper = styled.div`
  margin: 20px;

  & h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
    font-size: 21px;
  }

  & > .form-wrapper {
    border: 0.5px solid #ccc;
    background-color: #fff;
    padding: 20px;
  }

  & .title {
    padding: 8px 10px;
    font-size: 16px;
    background-color: #c89664;
    color: #fff;
    margin-bottom: 20px;
  }
`;

const Button = styled.div`
  padding: 10px 30px;
  background-color: ${props => props.color || "#c89664"};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;

  ${props => props.css}
`;

class MonthlyPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Wrapper>
        <h2>申請成為月結會員</h2>
        <div className="form-wrapper">
          <div className="title">月結會員申請表</div>
          <MonthlyApplyForm />
        </div>
      </Wrapper>
    );
  }
}

export default connect(null, ActionCreator)(MonthlyPanel);
