import React, { Component, Fragment } from "react";
import styled from "styled-components";
import * as Icon from "../Components/Icon";

class RadioSelectorGroupField extends Component {
  render() {
    const { options, value, onChange, horizontal, unableOption } = this.props;

    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: horizontal ? "row" : "column",
          }}
        >
          {options.map(option => (
            <CheckboxField
              key={option.value}
              onClick={() =>
                unableOption == option.value ? 0 : onChange(option.value)
              }
              extraCss={option.extraCss || ""}
            >
              <div className="box-wrapper">
                {value === option.value ? (
                  <Icon.RadioButtonChecked
                    color={unableOption == option.value ? "#eee" : "#BD9F84"}
                    size={20}
                  />
                ) : (
                  <Icon.RadioButtonUnchecked
                    color={unableOption == option.value ? "#eee" : "#BD9F84"}
                    size={20}
                  />
                )}
              </div>
              <p
                className="label-text"
                color={unableOption == option.value ? "#eee" : "#BD9F84"}
              >
                {option.label}
              </p>
            </CheckboxField>
          ))}
        </div>
      </Fragment>
    );
  }
}

const CheckboxField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 10px 0 0;
  cursor: pointer;
  ${props => props.extraCss};

  & > .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & > .label-text {
    font-size: 14px;
    color: #575757;
    margin-left: 6px;
  }

  & > .airport-label-text {
    font-size: 14px;
    color: #575757;
    margin-left: 6px;
    flex: 1;
  }

  & .icons {
    margin-left: 20px;
    max-width: 200px;

    & > .credit-card {
      width: 50px;
    }
  }

  & .single-icon-container {
    margin-left: 20px;
    min-width: 180px;
  }

  & .cvs {
    width: 35px;

    @media screen and (max-width: 375px) {
      width: 40px;
    }

    @media screen and (max-width: 360px) {
      width: 35px;
    }
  }
`;

export default RadioSelectorGroupField;
