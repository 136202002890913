import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Theme from "../Theme";

const Wrapper = styled.div``;

const FieldItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;

  & > label {
    display: block;
    margin-right: 10px;
    width: 160px;
    color: #343434;
  }

  & > input[type="text"],
  textarea {
    width: 200px;
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    padding: 8px 10px;
    outline: none;
    color: #101d1f;

    &:focus {
      box-shadow: 0px 0px 0px 1.5px lightgrey;
    }
  }
`;

const Button = styled.div`
  display: inline;
  float: right;
  padding: 10px 30px;
  background-color: ${props => props.color || `${Theme.colors.main}`};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;

  ${props => props.css}
`;

const Hint = styled.p`
  color: red;
`;

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      checkNewPassword: "",
      error: "",
    };
  }
  render() {
    let { error, password, newPassword, checkNewPassword } = this.state;
    let { profile } = this.props;
    let isNormalLogin = profile.provider !== "default";
    return (
      <Wrapper>
        <FieldItem>
          <label htmlFor={"password"}>舊的密碼</label>
          <input
            disabled={isNormalLogin}
            type="text"
            value={password}
            onChange={e => this.setState({ password: e.target.value })}
          />
        </FieldItem>
        <FieldItem>
          <label htmlFor={"newPassword"}>新的密碼</label>
          <input
            disabled={isNormalLogin}
            type="text"
            value={newPassword}
            onChange={e => this.setState({ newPassword: e.target.value })}
          />
        </FieldItem>
        <FieldItem>
          <label htmlFor={"newPassword"}>再輸入一次</label>
          <input
            disabled={isNormalLogin}
            type="text"
            value={checkNewPassword}
            onChange={e => this.setState({ checkNewPassword: e.target.value })}
          />
        </FieldItem>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            padding: 20,
          }}
        >
          {error != "" && <Hint>{error}</Hint>}
          <Button onClick={this._changePassword}>修改登入密碼</Button>
        </div>
      </Wrapper>
    );
  }

  _changePassword = async () => {
    let { appActions, profile } = this.props;
    let { newPassword, password } = this.state;
    this.setState({ error: "" });
    try {
      if (this._checkNewPasswordAvalible()) {
        let res = await appActions.changePassword({
          username: profile.email,
          password,
          newPassword,
        });
        window.alert("修改密碼成功！");
        this.setState({
          password: "",
          newPassword: "",
          checkNewPassword: "",
          error: "",
        });
      }
    } catch (err) {
      if (err.status == 401) {
        this.setState({ error: "舊密碼錯誤" });
      } else {
        this.setState({ error: "錯誤" });
      }
    }
  };

  _checkNewPasswordAvalible = () => {
    //put any verification algo here
    let { newPassword, checkNewPassword } = this.state;
    if (newPassword === checkNewPassword) {
      return true;
    } else {
      this.setState({ error: "確認密碼與新密碼不相同！" });
      return false;
    }
  };
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(ChangePasswordForm);
