import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import * as Icon from "../Components/Icon";

function Customer({ customer, onClick, selected }) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        padding: 8,
        margin: 8,
        borderBottom: "1px solid grey",
      }}
    >
      <div style={{ width: 40 }}>
        {selected && <Icon.Check size={24} color="black" />}
      </div>
      <div>
        <div>姓名: {customer.name || "---"}</div>
        <div>電話: {customer.phone || "---"}</div>
        <div>信箱: {customer.email || "---"}</div>
      </div>
    </div>
  );
}

class CustomOrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.defaultValues = {
      note: "",
      quantity: 1,
      amount: 100,
      config: {
        name: "",
        email: "",
        phone: "",
        invoiceConfig: {
          invoice_type: "two_copies",
          invoice_subtype: "mobile_vehicle",
          mobile_vehicle_code: "",
          citizen_personal_certificate_code: "",
          company_title: "",
          gui_number: "",
          donate_foundation_gui: "",
          love_code: "",
        },
        deliveryConfig: {
          delivery_type: "self_pick",
          is_delivery_private: false,
          receiver_address: "",
          receiver_name: "",
          receiver_phone: "",
          receiver_tel: "",
          zip: "",
          sender_address: "",
          sender_name: "",
          sender_phone: "",
          sender_tel: "",
          sender_zip: "",
        },
      },
    };

    this.state = {
      loading: false,
      error: null,
      values: {
        ...this.defaultValues,
      },
      searching: false,
      customers: [],
      buyer: null,
    };
  }

  render() {
    const { values, loading, error, searching, buyer, customers } = this.state;
    const { open, onClose } = this.props;

    return (
      <Ant.Modal
        visible={open}
        title="訂製商品"
        onCancel={() => {
          this._restoreToDefaultState();
          onClose();
        }}
        footer={null}
      >
        {error && <div style={{ color: "red" }}>{error}</div>}

        <h2>商品資訊</h2>

        <div style={{ marginBottom: 10 }}>
          <label htmlFor="note-input">商品名稱及備註</label>
          <Ant.Input.TextArea
            id="note-input"
            value={values.note}
            onChange={e =>
              this.setState({ values: { ...values, note: e.target.value } })
            }
          />
        </div>

        <div style={{ marginBottom: 10 }}>
          <label htmlFor="quantity-input">數量</label>
          <Ant.Input
            id="quantity-input"
            type="number"
            value={values.quantity}
            onChange={e =>
              this.setState({ values: { ...values, quantity: e.target.value } })
            }
          />
        </div>

        <div style={{ marginBottom: 10 }}>
          <label htmlFor="amount-input">價格</label>
          <Ant.Input
            id="amount-input"
            type="number"
            value={values.amount}
            onChange={e =>
              this.setState({ values: { ...values, amount: e.target.value } })
            }
          />
        </div>

        <h2>顧客資訊</h2>

        <div style={{ margin: "10px 0px" }}>
          <Ant.Input.Search
            placeholder="請輸入姓名,電話,或信箱"
            onSearch={this._searchCustomer}
            enterButton="搜尋顧客"
          />
        </div>

        {searching && (
          <Ant.Spin style={{ display: "block", margin: "0 auto" }} />
        )}

        {buyer && (
          <Customer
            key={"selected"}
            customer={buyer}
            selected={true}
            onClick={() => {
              this.setState({
                buyer: null,
                values: {
                  ...values,
                  config: {
                    ...values.config,
                    name: "",
                    phone: "",
                    email: "",
                  },
                },
              });
            }}
          />
        )}

        {!buyer &&
          customers.map(customer => (
            <Customer
              key={customer.id}
              customer={customer}
              selected={false}
              onClick={() => {
                this.setState({
                  buyer: customer,
                  values: {
                    ...values,
                    config: {
                      ...values.config,
                      name: customer.name,
                      phone: customer.phone,
                      email: customer.email,
                    },
                  },
                });
              }}
            />
          ))}

        <div style={{ marginBottom: 10 }}>
          <label>姓名</label>
          <Ant.Input
            value={values.config.name}
            onChange={e =>
              this.setState({
                values: {
                  ...values,
                  config: { ...values.config, name: e.target.value },
                },
              })
            }
          />
        </div>

        <div style={{ marginBottom: 10 }}>
          <label>電話</label>
          <Ant.Input
            value={values.config.phone}
            onChange={e =>
              this.setState({
                values: {
                  ...values,
                  config: { ...values.config, phone: e.target.value },
                },
              })
            }
          />
        </div>

        <div style={{ marginBottom: 10 }}>
          <label>EMAIL</label>
          <Ant.Input
            value={values.config.email}
            onChange={e =>
              this.setState({
                values: {
                  ...values,
                  config: { ...values.config, email: e.target.value },
                },
              })
            }
          />
        </div>

        <h2>發票資訊</h2>
        <Ant.Select
          style={{ marginBottom: 10 }}
          value={values.config.invoiceConfig.invoice_type}
          onChange={value =>
            this.setState({
              values: {
                ...values,
                config: {
                  ...values.config,
                  invoiceConfig: {
                    ...values.config.invoiceConfig,
                    invoice_type: value,
                  },
                },
              },
            })
          }
        >
          <Ant.Select.Option value="two_copies">二聯式</Ant.Select.Option>
          <Ant.Select.Option value="three_copies">三聯式</Ant.Select.Option>
          <Ant.Select.Option value="donate">捐贈</Ant.Select.Option>
        </Ant.Select>

        {values.config.invoiceConfig.invoice_type === "two_copies" && (
          <>
            <div>
              <Ant.Select
                style={{
                  marginBottom: 10,
                  width: 200,
                  display: "inline-block",
                }}
                value={values.config.invoiceConfig.invoice_subtype}
                onChange={value =>
                  this.setState({
                    values: {
                      ...values,
                      config: {
                        ...values.config,
                        invoiceConfig: {
                          ...values.config.invoiceConfig,
                          invoice_subtype: value,
                        },
                      },
                    },
                  })
                }
              >
                <Ant.Select.Option value="citizen_personal_certificate">
                  自然人憑證條碼
                </Ant.Select.Option>
                <Ant.Select.Option value="mobile_vehicle">
                  手機載具
                </Ant.Select.Option>
                <Ant.Select.Option value="ezpay_vehicle">
                  ezPay 電子發票載具
                </Ant.Select.Option>
              </Ant.Select>
            </div>

            {values.config.invoiceConfig.invoice_subtype ===
              "citizen_personal_certificate" &&
              this._renderInvoiceField(values)({
                label: "自然人憑證條碼16碼",
                field: "citizen_personal_certificate_code",
              })}

            {values.config.invoiceConfig.invoice_subtype === "mobile_vehicle" &&
              this._renderInvoiceField(values)({
                label: "手機載具條碼8碼",
                field: "mobile_vehicle_code",
              })}
          </>
        )}

        {values.config.invoiceConfig.invoice_type === "three_copies" &&
          this._renderInvoiceField(values)({
            label: "公司名稱",
            field: "company_title",
          })}

        {values.config.invoiceConfig.invoice_type === "three_copies" &&
          this._renderInvoiceField(values)({
            label: "統一編號",
            field: "gui_number",
          })}

        {values.config.invoiceConfig.invoice_type === "donate" &&
          this._renderInvoiceField(values)({
            label: "捐贈單位統編",
            field: "donate_foundation_gui",
          })}

        {values.config.invoiceConfig.invoice_type === "donate" &&
          this._renderInvoiceField(values)({
            label: "愛心碼",
            field: "love_code",
          })}

        <h2>收件方式</h2>

        <Ant.Select
          style={{ marginBottom: 10 }}
          value={values.config.deliveryConfig.delivery_type}
          onChange={value =>
            this.setState({
              values: {
                ...values,
                config: {
                  ...values.config,
                  deliveryConfig: {
                    ...values.config.deliveryConfig,
                    delivery_type: value,
                  },
                },
              },
            })
          }
        >
          <Ant.Select.Option value="self_pick">自取</Ant.Select.Option>
          <Ant.Select.Option value="hct">宅配</Ant.Select.Option>
        </Ant.Select>

        {values.config.deliveryConfig.delivery_type === "hct" && (
          <>
            <h2>收件人資訊</h2>

            {this._renderDeliveryField(values)({
              label: "收件人地址",
              field: "receiver_address",
            })}
            {this._renderDeliveryField(values)({
              label: "收件人郵遞區號",
              field: "zip",
            })}
            {this._renderDeliveryField(values)({
              label: "收件人姓名",
              field: "receiver_name",
            })}
            {this._renderDeliveryField(values)({
              label: "收件人手機",
              field: "receiver_phone",
            })}
            {this._renderDeliveryField(values)({
              label: "收件人市話",
              field: "receiver_tel",
            })}

            <div>
              <Ant.Checkbox
                checked={values.config.deliveryConfig.is_delivery_private}
                onChange={e => {
                  this.setState({
                    values: {
                      ...values,
                      config: {
                        ...values.config,
                        deliveryConfig: {
                          ...values.config.deliveryConfig,
                          is_delivery_private: e.target.checked,
                        },
                      },
                    },
                  });
                }}
              >
                保密代寄
              </Ant.Checkbox>
            </div>

            {values.config.deliveryConfig.is_delivery_private && (
              <>
                <h2>寄件人資訊</h2>
                {this._renderDeliveryField(values)({
                  label: "寄件人地址",
                  field: "sender_address",
                })}
                {this._renderDeliveryField(values)({
                  label: "寄件人郵遞區號",
                  field: "sender_zip",
                })}
                {this._renderDeliveryField(values)({
                  label: "寄件人姓名",
                  field: "sender_name",
                })}
                {this._renderDeliveryField(values)({
                  label: "寄件人手機",
                  field: "sender_phone",
                })}
                {this._renderDeliveryField(values)({
                  label: "寄件人市話",
                  field: "sender_tel",
                })}
              </>
            )}
          </>
        )}

        <BottomBar>
          <Ant.Button
            type="primary"
            loading={loading}
            onClick={this._createOrder}
          >
            送出訂單
          </Ant.Button>
        </BottomBar>
      </Ant.Modal>
    );
  }

  _renderInvoiceField = values => ({ label, field }) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <label>{label}</label>
        <Ant.Input
          value={values.config.invoiceConfig[field]}
          onChange={e =>
            this.setState({
              values: {
                ...values,
                config: {
                  ...values.config,
                  invoiceConfig: {
                    ...values.config.invoiceConfig,
                    [field]: e.target.value,
                  },
                },
              },
            })
          }
        />
      </div>
    );
  };

  _renderDeliveryField = values => ({ label, field }) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <label>{label}</label>
        <Ant.Input
          value={values.config.deliveryConfig[field]}
          onChange={e =>
            this.setState({
              values: {
                ...values,
                config: {
                  ...values.config,
                  deliveryConfig: {
                    ...values.config.deliveryConfig,
                    [field]: e.target.value,
                  },
                },
              },
            })
          }
        />
      </div>
    );
  };

  _restoreToDefaultState = () => {
    this.setState({
      values: {
        ...this.defaultValues,
      },
      buyer: null,
      customers: [],
    });
  };

  _searchCustomer = async keyword => {
    const { appActions } = this.props;

    if (!keyword) {
      return;
    }

    this.setState({ searching: true, buyer: null, customers: [] });

    try {
      this.setState({ customers: await appActions.searchProfile({ keyword }) });
    } catch (ex) {
      // bypass
    }

    this.setState({ searching: false });
  };

  _createOrder = async () => {
    const { appActions, onClose } = this.props;
    const { buyer } = this.state;
    const { note, quantity, amount, config } = this.state.values;

    if (!note || !quantity || !amount || !buyer) {
      alert("訂製單所有欄位都需填寫!");
      return;
    }

    this.setState({ loading: true });

    try {
      await appActions.createCustomOrder({
        amount: parseInt(amount),
        quantity: parseInt(quantity),
        buyer: buyer.id,
        note,
        config,
      });

      alert("創建訂單成功！");
      this._restoreToDefaultState();
      onClose();
    } catch (ex) {
      alert("訂製單欄位格式有誤！");
    }

    this.setState({ loading: false });
  };
}

const BottomBar = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export default connect((state, ownProps) => ({}), ActionCreator, null, {
  forwardRef: true,
})(CustomOrderModal);
