import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import ProfileForm from "./ProfileForm";
import InvoiceForm, {
  ALL_SUPPORTED_RECEIPT_TYPE,
  RECEIPT_TWO_COPIES_TYPES,
} from "./InvoiceForm";
import * as Cart from "../Contexts/CartContext";
import * as Theme from "../Theme";

const Wrapper = styled.div`
  margin: 20px;

  & h2 {
    color: #5a5a5a;
    margin-bottom: 10px;
    font-size: 21px;
  }

  & > .form-wrapper {
    border: 0.5px solid #ccc;
    background-color: #fff;
    padding: 20px;
  }

  & .title {
    padding: 8px 10px;
    font-size: 16px;
    background-color: ${Theme.colors.main};
    color: #fff;
    margin-bottom: 20px;
  }
`;

const Button = styled.div`
  padding: 10px 30px;
  background-color: ${props => props.color || Theme.colors.main};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;

  ${props => props.css}
`;

class ProfilePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Wrapper>
        <h2>基本資料</h2>
        <div className="form-wrapper">
          <div className="title">會員基本資料</div>
          <ProfileForm /> {/* use old sensation repo comps below this layer */}
        </div>
      </Wrapper>
    );
  }
}

export default connect(null, ActionCreator)(Cart.withConsumer(ProfilePanel));
