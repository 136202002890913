import React from "react";
import styled from "styled-components";
import * as Icon from "./Icon";
import * as Theme from "../Theme";
import * as Widget from "./Widget";
import * as Ant from "antd";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  background-color: #f4f4f4;

  & > .admin-rwd-toggle {
    display: none;

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 20px;
      bottom: 20px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      cursor: pointer;
      background-color: ${Theme.colors.main};
    }
  }

  & .admin-sidebar {
    @media screen and (max-width: 1000px) {
      padding: 10px;
      background-color:${Theme.colors.main};
      position: fixed;
      height: auto;
      left: ${props => (props.rwdSidebarOpen ? "0px" : "-200px")};
      top: ${Theme.navbarHeight + 30}px;
      bottom: 0;
      transition: left 200ms;

      .entries {
        border: none;
      }
    }

    overflow-y: auto;
    padding-top: 60px;
    width: 200px;
    flex-shrink: 0;
    /* color: #595757; */
    /* background-color: #fafafa; */
    z-index: 1;

    .site-title {
      padding: 10px;
      font-weight: bold;
      color: ${Theme.colors.main};
    }

    .entries {
      background-color: #ffffff;
      margin-top: 15px;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
    }

    .entry {
      color: #444444;
      border-bottom: 0.5px solid #d9d9d9;
      background-color: #fff;
      /* margin: 15px; */
      padding: 10px 18px;
      display: flex;
      cursor: pointer;

      :last-child {
        border: 0px;
      }
    }

    .entry.selected {
      /* only level2 use this css so far*/
      color: ${Theme.colors.main};
    }

    .entry .label {
      flex: 1;
    }

    .entry.level2.selected {
      /* only level2 use this css so far*/
      color: ${Theme.colors.main};
      /* background-color: ${Theme.colors.main}; */
    }

    .entry.level2 {
      color: #595757;
      margin: 0px;
      border: 0px;
      background-color: #fff;
      padding-left: 32px;
      font-size: 0.8rem;
    }

    .entry:hover {
      color: #6b6b6b;
      cursor: pointer;
    }
  }

  & > .admin-tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: auto;
  }
  ${props => props.css}
`;

class CmsDashboard extends React.Component {
  static defaultProps = {
    Header: "Revtel Admin",
    entryFunctionHandler: () => 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: this.props.entries[0],
      rwdSidebarOpen: false,
    };
  }

  render() {
    let { entries, css } = this.props;
    let { selectedEntry, rwdSidebarOpen } = this.state;
    return (
      <Wrapper css={css} themeColor={"#4D525D"} rwdSidebarOpen={rwdSidebarOpen}>
        {/* <Ant.Row style={{ flex: 1 }}> */}
        {/* <Ant.Col
            xs={0}
            sm={0}
            md={0}
            lg={5}
            style={{
              backgroundColor: "#f4f4f4",
              minHeight: "calc(100vh - 60px)",
              padding: "30px 10px",
            }}
          > */}
        <div className="admin-sidebar">
          {this._renderHeader()}
          <div className="entries">
            {entries.map(entry => (
              <CmsEntry
                key={entry.name}
                isHidden={entry.hidden}
                entry={entry}
                selectedEntry={selectedEntry}
                level={1}
                onSelectEntry={this._onSelectEntry}
              />
            ))}
          </div>
        </div>
        {/* </Ant.Col> */}
        {/* <Ant.Col
            xs={24}
            sm={24}
            md={24}
            lg={19}
            style={{ padding: "30px 20px" }}
          > */}
        <div className="admin-tab-content">{this._renderContent()}</div>

        {/* <div className="admin-rwd-toggle" onClick={this._toggleRwdSidebar}>
                <Icon.Apps size={24} color="white" />
              </div> */}
        <Widget.ToggleDrawer onClick={this._toggleRwdSidebar}>
          <Icon.Apps size={24} color="white" />
        </Widget.ToggleDrawer>
        {/* </Ant.Col> */}
        {/* </Ant.Row> */}
      </Wrapper>
    );
  }

  _renderHeader = () => {
    let { Header } = this.props;
    let { selectedEntry } = this.state;

    if (typeof Header === "string") {
      return <div className="site-title">Revtel Admin</div>;
    } else if (typeof Header === "function") {
      // Component
      return <Header selectedEntry={selectedEntry} />;
    } else if (typeof Header === "object") {
      // Element
      return Header;
    }
  };

  _renderContent = () => {
    let { selectedEntry } = this.state;
    try {
      return this.props.children({
        cms: {
          entry: selectedEntry,
          onSelect: entry => this.setState({ selectedEntry: entry }),
        },
      });
    } catch (ex) {
      return <div>{selectedEntry.name}</div>;
    }
  };

  _onSelectEntry = entry => {
    let { entryFunctionHandler } = this.props;
    if (entry.type === "function") {
      entryFunctionHandler(entry);
    } else {
      this.setState({ selectedEntry: entry, rwdSidebarOpen: false });
    }
  };

  _toggleRwdSidebar = () => {
    let { rwdSidebarOpen } = this.state;
    this.setState({ rwdSidebarOpen: !rwdSidebarOpen });
  };
}

class CmsEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: true,
    };
  }

  render() {
    let { entry, level, selectedEntry, onSelectEntry, isHidden } = this.props;
    let { expand } = this.state;
    let isSelected = selectedEntry.name === entry.name;
    if (!isHidden) {
      return (
        <>
          <div
            className={
              "entry " + `level${level} ` + (isSelected ? "selected" : "")
            }
            onClick={this._onEntryClick}
          >
            <div className="label">{entry.label}</div>

            {entry.entries && (
              <div className="expand">{expand ? "-" : "+"}</div>
            )}
          </div>

          {expand &&
            entry.entries &&
            entry.entries.map(e => (
              <CmsEntry
                level={level + 1}
                entry={e}
                selectedEntry={selectedEntry}
                onSelectEntry={onSelectEntry}
                key={e.name}
              />
            ))}
        </>
      );
    } else {
      return null;
    }
  }

  _onEntryClick = () => {
    let { entry, onSelectEntry } = this.props;
    let { expand } = this.state;

    if (entry.entries) {
      this.setState({ expand: !expand });
    } else {
      onSelectEntry(entry);
    }
  };
}

export default CmsDashboard;
