import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as Ant from "antd";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as AppEvents from "../AppEvents";
import OrderList, { FilterStatusTypes } from "../Components/OrderList";
import CreditPanel, { creditUiTypes } from "../Components/CreditPanel";
import CmsDashboard from "../Components/CmsDashboard";
import MyCourseList from "../Components/MyCourseList";
import ProfilePanel from "../Components/ProfilePanel";
import ChangePwPanel from "../Components/ChangePwPanel";
import MonthlyApplyPanel from "../Components/MonthlyApplyPanel";
import MonthlyIntroPanel from "../Components/MonthlyIntroPanel";
import Constants from "../constants";
import * as Theme from "../Theme";
import RequestEntDialog from "../Components/RequestEntDialog";
import RequestMonthlyDialog from "../Components/RequestMonthlyDialog";
import * as Icon from "../Components/Icon";

const USER_TYPE = {
  normal: "一般會員",
  vip: "預儲會員",
  enterprise: "企業會員",
  ent_vip: "企業預繳會員",
  monthly: "企業月結會員",
  ent_vip_monthly: "企業月結預儲會員",
};

const PROVIDER = {
  default: "一般登入",
  facebook: "facebook登入",
  google: "google登入",
  apple: "apple登入",
};
class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: "",
      openRequestEntDialog: false,
      openRequestMonthlyDialog: false,
    };
  }

  render() {
    const { profile, navActions } = this.props;
    const {
      selectedEntry,
      openRequestEntDialog,
      openRequestMonthlyDialog,
    } = this.state;
    /*
  each member type can request to be (always cannot to request vip)

  normal --> interprise

  vip --> ent_vip

  enterprise --> monthly

  ent_vip --> ent_vip_monthly
  */
    return (
      <Wrapper>
        <div className="center-content">
          {!profile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="../../images/error-login.png"
                width="70%"
                style={{ maxWidth: 400, marginBottom: 30 }}
              />
              <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: 5 }}>
                很抱歉，您尚未登入！
              </p>
              <p style={{ fontSize: 16, fontWeight: "bold" }}>
                請登入會員或前往註冊新帳號。
              </p>
              <Ant.Button type="primary" onClick={this._openLoginModal}>
                登入
              </Ant.Button>
            </div>
          ) : (
            <div className="row">
              <CmsDashboard
                Header={
                  <MyProfileBlock avatar={profile.avatar}>
                    {/* <div className="avatar">
                      <Icon.Person color="white" size={40} />
                    </div> */}
                    <h4>{PROVIDER[profile.provider]}</h4>
                    <h4>
                      {profile.name || profile.user.username || profile.email}
                    </h4>
                  </MyProfileBlock>
                }
                entries={
                  [
                    { name: "myCourseList", label: "我的課程" },
                    { name: "order", label: "我的帳單" },
                    { name: "myProfile", label: "基本資料" },
                    { name: "changePw", label: "修改登入密碼" },
                    { name: "logout", label: "登出", type: "function" },
                  ]
                  //   [
                  //   {
                  //     name: "order",
                  //     label: "訂單總覽",
                  //     // entries: [{ name: "order", label: "全部訂單" }],
                  //   },
                  //   {
                  //     name: "memberInfo",
                  //     label: "個人資料管理",
                  //     entries: [
                  //       { name: "myProfile", label: "基本資料" },
                  //       { name: "changePw", label: "修改登入密碼" },
                  //       // { name: "address", label: "訂單常用地址" },
                  //     ],
                  //   },

                  //   {
                  //     name: "logout",
                  //     label: "Logout",
                  //     type: "function",
                  //   },
                  // ]
                }
                entryFunctionHandler={this._onEntryFunction}
              >
                {({ cms }) => {
                  let { entry, onSelect } = cms;
                  return (
                    <>
                      {(() => {
                        if (entry.name === "myCourseList") {
                          return <MyCourseList />;
                        } else if (entry.name === "depositCredit") {
                          return (
                            <CreditPanel uiType={creditUiTypes.TO_DEPOSIT} />
                          );
                        } else if (entry.name === "depositRecord") {
                          return (
                            <CreditPanel
                              uiType={creditUiTypes.DEPOSIT_RECORD}
                            />
                          );
                        } else if (entry.name === "creditRecord") {
                          return (
                            <CreditPanel uiType={creditUiTypes.CREDIT_RECORD} />
                          );
                        } else if (entry.name === "monthlyRecord") {
                          return <OrderList type={FilterStatusTypes.MONTHLY} />;
                        } else if (entry.name === "order") {
                          return <OrderList type={FilterStatusTypes.NORMAL} />;
                        } else if (entry.name === "orderWaiting") {
                          return (
                            <OrderList
                              type={FilterStatusTypes.WAITING_OR_PROCESSING}
                            />
                          );
                        } else if (entry.name === "orderItemRejected") {
                          return (
                            <OrderList
                              type={FilterStatusTypes.ORDER_ITEM_REJECTED}
                            />
                          );
                        } else if (entry.name === "orderFinished") {
                          return <OrderList type={FilterStatusTypes.SUCCESS} />;
                        } else if (entry.name === "myProfile") {
                          return <ProfilePanel />;
                        } else if (entry.name === "changePw") {
                          return <ChangePwPanel />;
                        } else if (entry.name === "monthlyIntro") {
                          return (
                            <MonthlyIntroPanel
                              onEntry={() =>
                                onSelect({
                                  name: "applyToBeMothly",
                                  label: "申請成為月結會員",
                                })
                              }
                            />
                          );
                        } else if (entry.name === "applyToBeMothly") {
                          return <MonthlyApplyPanel />;
                        }
                        throw "default";
                      })()}
                    </>
                  );
                }}
              </CmsDashboard>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }

  _onEntryFunction = entry => {
    let { appActions } = this.props;
    if (entry.name === "logout") {
      appActions.logout().catch(console.warn);
    }
  };

  _openLoginModal = () => {
    AppEvents.EvtShowLoginModal.emit(true);
  };

  _logout = () => {
    const { appActions } = this.props;
    appActions.logout();
  };
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f4f4f4;
  @media screen and (max-width: ${Theme.breakpoints.sm}px) {
    padding: 0px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .center-content {
    max-width: 1240px;
    margin: 20px auto;
  }
`;

const Button = styled(Ant.Button)`
  border: 0px;
  margin-top: 5px;
  background-color: ${props =>
    props.disable ? "lightgrey" : Theme.colors.main};
`;

const MyProfileBlock = styled.div`
  margin-bottom: 15px;
  border: 0.5px solid #d9d9d9;
  background-color: white;
  border-radius: 8px;
  color: #575757;
  padding: 12px 8px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: url(${props => props.avatar});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background: #dcdddd; */
    /* background: linear-gradient(45deg, #dc7c24 0%, #f0e658 100%); */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .h2 {
    margin: 0px;
    padding: 0px;
    color: ${Theme.colors.main};
  }

  & > h4 {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    color: ${Theme.colors.main};
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(ProfilePage)
);
