import React from "react";
import styled from "styled-components";

const LabelText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin: 0px;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 25px;
  background-color: ${props => props.color || "#C24C4C"};
  border-radius: 15px;
  padding: 0px 10px;
`;

class StatusLabelGenerator extends React.Component {
  render() {
    let { text, color } = this.props;
    return (
      <LabelWrapper color={color}>
        <LabelText>{text}</LabelText>
      </LabelWrapper>
    );
  }
}

export default StatusLabelGenerator;
