import React from "react";
import * as Ant from "antd";
import styled from "styled-components";
import StatusLabelGenerator from "./statusLabel";
import * as Icon from "./Icon";
import OrderItemsDialog from "./OrderItemsDialog";
import * as Theme from "../Theme";
import { getOrderStatus } from "../Utils/OrderUtil";

class OrderStatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      openDialog: false,
      dialogType: "",
    };
  }

  componentDidMount() {
    let { openDialog } = this.state;
    let { order } = this.props;
    let status = getOrderStatus({ order });
    this.setState({ step: status.step });
  }

  render() {
    let { step, openDialog, dialogType, title } = this.state;
    let { order } = this.props;
    let rejected = false;
    if (order.is_item_rejected) {
      rejected = true;
    }

    return (
      <Ant.Row gutter={16}>
        <Ant.Col span={4}>
          <ItemWrapper isActived={step >= 1} isRejected={rejected}>
            <p className="title">訂單成立</p>
            <div className="line-wrapper">
              <div className="point">
                <Icon.Check color="white" size={20} />
              </div>
              <div className="line"></div>
            </div>
            <p className="subtitle">訂單成立</p>
            <p className="data">{`成立時間`}</p>
            <p className="data">{`${order.created.slice(0, 10)}`}</p>
          </ItemWrapper>
        </Ant.Col>
        <Ant.Col span={4}>
          <ItemWrapper isActived={step >= 2} isRejected={rejected}>
            <p className="title">付款階段</p>
            <div className="line-wrapper">
              <div className="point">
                <Icon.Check color="white" size={20} />
              </div>
              <div className="line"></div>
            </div>

            {order.payment_status === "success" && (
              <p className="subtitle">付款成功</p>
            )}
          </ItemWrapper>
        </Ant.Col>
        <Ant.Col span={4}>
          <ItemWrapper isActived={step >= 3} isRejected={rejected}>
            <p className="title">審稿階段</p>
            <div className="line-wrapper">
              <div className="point">
                {rejected && step === 2 ? (
                  <Icon.Close color="white" size={20} />
                ) : (
                  <Icon.Check color="white" size={20} />
                )}
              </div>
              <div className="line"></div>
            </div>
            {step >= 3 && <p className="subtitle">審稿完成</p>}
            <Ant.Button
              type="primary"
              onClick={() =>
                this.setState({
                  openDialog: true,
                  dialogType: "review",
                })
              }
            >
              稿件資訊
            </Ant.Button>
          </ItemWrapper>
        </Ant.Col>
        <Ant.Col span={4}>
          <ItemWrapper isActived={step >= 4} isRejected={rejected}>
            <p className="title">印製階段</p>
            <div className="line-wrapper">
              <div className="point">
                <Icon.Check color="white" size={20} />
              </div>
              <div className="line"></div>
            </div>
            {step >= 4 && <p className="subtitle">商品已入庫</p>}
            <Ant.Button
              type="primary"
              onClick={() =>
                this.setState({
                  openDialog: true,
                  dialogType: "production",
                })
              }
            >
              商品資訊
            </Ant.Button>
          </ItemWrapper>
        </Ant.Col>

        <Ant.Col span={4}>
          <ItemWrapper isActived={step >= 5} isRejected={rejected}>
            <p className="title">出貨階段</p>
            <div className="line-wrapper">
              <div className="point">
                <Icon.Check color="white" size={20} />
              </div>
              <div className="line"></div>
            </div>
            {step >= 5 && <p className="subtitle">已到貨/已自取</p>}
            <Ant.Button
              type="primary"
              onClick={() =>
                this.setState({
                  openDialog: true,
                  dialogType: "logistic",
                })
              }
            >
              物流資訊
            </Ant.Button>
          </ItemWrapper>
        </Ant.Col>

        <Ant.Col span={4}>
          <ItemWrapper isActived={step >= 6}>
            <p className="title">訂單完成</p>
            <div className="line-wrapper">
              <div className="point">
                <Icon.Check color="white" size={20} />
              </div>
              {/* <div className="line"></div> */}
            </div>
          </ItemWrapper>
        </Ant.Col>
        {/* <Ant.Modal
          title="Basic Modal"
          visible={this.state.openDialog}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
        </Ant.Modal> */}
        {openDialog && (
          <OrderItemsDialog
            order={order}
            dialogType={dialogType}
            closeDialog={() => this.setState({ openDialog: false })}
          />
        )}
      </Ant.Row>
    );
  }

  // _generateOrderStep = order => {
  //   //step = 1 //訂單建立
  //   let step = 1;
  //   if (order.payment_status !== "success") {
  //     step = 1;
  //   }

  //   //step = 2 //付款成功
  //   if (order.payment_status === "success" && order.is_item_accepted == false) {
  //     step = 2;
  //   }

  //   //step = 3 //審稿階段
  //   if (
  //     order.payment_status === "success" &&
  //     order.is_item_accepted == true &&
  //     order.is_item_manufacture == false
  //   ) {
  //     step = 3;
  //   }

  //   //step = 4 //印製階段
  //   if (
  //     order.is_item_accepted == true &&
  //     order.is_item_manufacture == true && //is_item_manufacture == 已入庫
  //     order.logistics[0] &&
  //     order.logistics[0].logistics_status !== "transit"
  //   ) {
  //     step = 4;
  //   }

  //   //step = 5 //可取貨/已出貨
  //   if (
  //     order.logistics[0] &&
  //     order.logistics[0].logistics_status === "transit"
  //   ) {
  //     step = 5;
  //   }

  //   //step = 6//訂單完成
  //   if (
  //     order.logistics[0] &&
  //     order.logistics[0].logistics_status === "delivered"
  //   ) {
  //     step = 6;
  //   }

  //   this.setState({ step });
  //   console.log(" this._generateOrderStep(order)????", step);
  //   /**
  //    * order.logistics[0].logistics_status
  //     tallying: "等待理貨中", //hct:理貨中(尚未填入追蹤碼)
  //     transit: "可自取", //hct:已出貨
  //     pickup: "已自取", //hct:出貨中
  //     pending: "理貨中",
  //     notfound: "無追蹤中包裹",
  //     delivered: "已送達",
  //     undelivered: "未送達",
  //     exception: "例外事故",
  //     expired: "過期",
  //     default: "預設" // 不確定用途，hct 無此狀態
  //    */
  //   return;
  // };
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 30px;
  position: relative;
  & > .line-wrapper {
    margin-bottom: 15px;
    & > .line {
      position: absolute;
      width: 100%;
      height: 5px;
      top: 46px;
      left: 45%;
      background-color: ${props =>
        props.isRejected && props.isActived
          ? "#C24C4C"
          : props.isActived
          ? Theme.colors.main
          : "#cbcbcb"};
    }

    & > .point {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: ${props =>
        props.isRejected && props.isActived
          ? "#C24C4C"
          : props.isActived
          ? Theme.colors.main
          : "#cbcbcb"};
    }
  }

  & > p {
    font-size: 13px;
    color: ${props => (props.isActived ? "#444444" : "#cbcbcb")};
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  & > .title {
    font-size: 14px;
    color: #595757;
  }

  & > .subtitle {
    font-size: 12px;
    color: #595757;
  }

  & > .data {
    font-size: 8px;
    color: #9fa0a0;
    text-align: center;
  }

  & > .hint {
    color: red;
  }
`;

export default OrderStatusBar;
