import React, { Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Cart from "../Contexts/CartContext";
import {
  DialogWrapper,
  Input,
  Button,
  Selector,
  TextArea,
  Title,
} from "./DialogWidgets";
import * as Icon from "./Icon";
import moment from "moment";
import * as Ant from "antd";
import StatusLabelGenerator from "./statusLabel";
import * as Theme from "../Theme";

const REVIEW_STATUS = {
  waiting: { display: "未審稿", color: "#999" },
  pending: { display: "審稿中", color: "#D7BD23" },
  accepted: { display: "合格", color: "#4BAA70" },
  rejected: { display: "不合格", color: "#C24C4C" },
  void: { display: "作廢", color: "#C24C4C" },
  XXX: { display: "補印件", color: "#C24C4C" }, //TODO: status of 補印件
  // { "failure": "補收款"},
};

const PORDUCTION_STATUS = {
  waiting: { display: "等待中", color: "#C24C4C" },
  pending: { display: "已審未製作", color: "#C24C4C" },
  preparing: { display: "工單已交付", color: "#D7BD23" },
  in_production: { display: "製作中", color: "#D7BD23" },
  completed: { display: "印製完成", color: "#4BAA70" },
  in_storage: { display: "已入庫", color: "#4BAA70" },
};

const LOGISTIC_STATUS = {
  tallying: "理貨中",
  transit: "已出貨",
  pickup: "出貨中",
  pending: "理貨中",
  notfound: "無追蹤中包裹",
  delivered: "已送達",
  undelivered: "未送達",
  exception: "例外事故",
  expired: "過期",
  default: "預設", // 不確定用途，hct 無此狀態
};
const LOGISTIC_STATUS_SELF = {
  tallying: "等待理貨中", //hct:理貨中(尚未填入追蹤碼)
  transit: "可自取", //hct:已出貨
  pickup: "出貨中",
  pending: "理貨中",
  notfound: "無追蹤中包裹",
  delivered: "已自取", //hct: 已送達
  undelivered: "未送達",
  exception: "例外事故",
  expired: "過期",
  default: "預設", // 不確定用途，hct 無此狀態
};

const DIALOG_TYPE = {
  review: "審稿",
  production: "印製",
  logistic: "物流",
};

class OrderItemsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderItems: [],
    };
  }

  async componentDidMount() {
    let { appActions, order } = this.props;
    try {
      let orderItems = await Cart.Actions.getItemsByOrderId(order.id);
      this.setState({ orderItems });
    } catch (ex) {
      console.warn(ex);
    }
  }

  render() {
    let { closeDialog, dialogType, order } = this.props;
    let { orderItems } = this.state;

    let cartData = JSON.parse(order.cart);
    return (
      <Ant.Modal
        visible={true}
        title={<Title>{`${DIALOG_TYPE[dialogType]}狀態`}</Title>}
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              確定
            </Button>
          </div>
        }
        closeIcon={
          <Icon.Close
            style={{ position: "absolute", right: 20, top: 20 }}
            size={20}
          />
        }
        onCancel={closeDialog}
        style={{
          textAlign: "center",
        }}
        width="80%"
      >
        <DialogWrapper>
          <div className="content">
            {(dialogType === "review" || dialogType === "production") &&
              orderItems &&
              orderItems.map((item, idx) => {
                return (
                  <>
                    <div className="row" key={idx} style={{ flexWrap: "wrap" }}>
                      <p
                        style={{
                          width: 45, // fix!
                          paddingBottom: 2,
                          marginRight: 10,
                          borderBottom: "1px solid #555555",
                        }}
                      >{`稿件${item.item_index + 1}.`}</p>
                      <p style={{ marginRight: 5 }}>
                        {cartData.items[idx].name}
                      </p>
                      <p style={{ marginRight: 5 }}>
                        {cartData.items[idx].quantity}
                      </p>
                      <p style={{ marginRight: 15 }}>{item.unit} 單位</p>
                      <p>
                        {dialogType == "review" ? (
                          <StatusLabelGenerator
                            text={REVIEW_STATUS[`${item.status}`].display}
                            color={REVIEW_STATUS[`${item.status}`].color}
                          />
                        ) : (
                          <StatusLabelGenerator
                            text={
                              PORDUCTION_STATUS[`${item.production_status}`]
                                .display
                            }
                            color={
                              PORDUCTION_STATUS[`${item.production_status}`]
                                .color
                            }
                          />
                        )}
                      </p>
                    </div>
                    <div
                      className="row"
                      key={idx}
                      style={{
                        flexWrap: "wrap",
                        marginLeft: 45, // fix!
                      }}
                    >
                      {item.status == "rejected" && (
                        <div
                          className="row"
                          style={{ marginLeft: 15, marginBottom: 0 }}
                        >
                          <p>不合格原因：</p>
                          <p style={{ color: Theme.colors.main }}>
                            {(item.reject_reason && item.reject_reason) ||
                              "無填寫不合格原因，請洽詢客服"}
                          </p>
                        </div>
                      )}

                      {item.note && item.note !== "" && (
                        <div
                          className="row"
                          style={{ marginLeft: 15, marginBottom: 0 }}
                        >
                          <p>審稿人員備註：</p>
                          <p style={{ color: Theme.colors.main }}>
                            {item.note}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                );
              })}
            {dialogType === "logistic" &&
              (order.logistics[0] ? (
                <div className="row">
                  <p
                    style={{
                      paddingBottom: 2,
                      marginRight: 10,
                      borderBottom: "1px solid #555555",
                    }}
                  >
                    狀態
                  </p>
                  <p style={{ marginRight: 5 }}>
                    {order.logistics[0].logistics_type === "self_pick"
                      ? LOGISTIC_STATUS_SELF[
                          order.logistics[0].logistics_status
                        ]
                      : LOGISTIC_STATUS[order.logistics[0].logistics_status]}
                  </p>
                </div>
              ) : (
                <p>無出貨單</p>
              ))}
          </div>
          {order.is_item_rejected && (
            <Ant.Alert
              message="若有不合格稿件，請至詳細頁面重新上傳"
              type="error"
            />
          )}
        </DialogWrapper>
      </Ant.Modal>
    );
  }

  _createRecord = async () => {
    let { record } = this.state;
    let { closeDialog, onUpdate, orderItem } = this.props;

    try {
      await Cart.Actions.createRefund({
        status: "waiting",
        note: record.note,
        amount: 0,
        order: orderItem.order,
        item_index: orderItem.item_index,
      });
      onUpdate();
      closeDialog();
    } catch (err) {
      console.warn(err);
    }
  };
}

export default OrderItemsDialog;
